<template>
    <div>
    <br>
        <!-- <div class="container">
        <div class="row">
                <div class="col-md-6 mx-auto"> -->
                    <div style="width:400px" class="card">
                        <div class="card-header text-center">
                            <span style="cursor:pointer">Chat Box</span>
                            <i style="cursor:pointer" @click="$emit('closeChatBox')" class="fa fa-close float-left mt-1"></i>
                        </div>
                        <div class="card-body chat-care" id="messages">
                            <div class="top">
                                <small class="left text-muted">
                                    <span class="glyphicon glyphicon-time">
                                    </span>{{moment(item.created_at).fromNow()}}
                                </small> <hr style="margin: 10px 0">
                                <h6 class="top-title clearfix">{{ item.title }}</h6>
                                <p class="top-details">{{ item.details }}</p>
                                
                            </div>
                            <template v-for="chat in  item.replies" >
                                <div v-if="chat.is_user == 1" :key="chat.id" class="user">
                                    <small class="left text-muted"><span class="glyphicon glyphicon-time"></span>{{moment(chat.created_at).fromNow()}}</small>
                                    <p style="margin:0" class="cat-text">{{ chat.message }}</p>
                                </div>
                                <div v-else :key="chat.id" class="replay">
                                    <small class="left text-muted"><span class="glyphicon glyphicon-time"></span>{{moment(chat.created_at).fromNow()}}</small>
                                    <p style="margin:0" class="cat-text">{{ chat.message }}</p>
                                </div>
                            </template>
                            <!-- <ul class="chat">
                                <li class="admin clearfix" v-for="(item, index) in chat" :key="index">
                                    <div  class="chat-body clearfix">
                                        <div class="header clearfix">
                                            <small class="left text-muted"><span class="glyphicon glyphicon-time"></span>15 mins ago</small>
                                        </div>
                                        <p>
                                           {{ item.details }}
                                        </p>
                                    </div>
                                </li>
                            </ul> -->
                        </div>
                        <div class="card-footer">
                           
                            <div v-if="item.status == 'open'" class="input-group">
                                <input v-model="message.message" id="btn-input" type="text" class="form-control input-sm" placeholder="Type your message here..." />
                                <span class="input-group-btn">
                                    <button style="margin-left: 10px" @click="onClickSend()" class="btn btn-primary" id="btn-chat">
                                        Send</button>
                                </span>
                            </div>
                             <h5 v-else >You can't reply. </h5>
                        </div>
                    </div>
                </div>
            <!-- </div>
        </div>
    </div> -->
</template>

<script>
export default {
    name: 'chat-box',
    data(){
        return {
            message: {},
        }
    },
    methods: {
        onClickSend(){
            this.message.order_support_id = this.item.id
            this.$emit('onClickSend', this.message)
            this.message = {}
        }
    },//end of methods
    props:{
        item: {required: true}
    },
    // mounted(){
    //     console.log(this.chat,'>>>chat>>>>>')
    // }
}
</script>

<style scoped>
    .chat
{
    list-style: none;
    margin: 0;
    padding: 0;
}
.chat-care
{
    overflow-y: scroll;
    height: 250px;
}
.chat-care .chat-img
{
    width: 50px;
    height: 50px;
}
.chat-care .img-circle
{
    border-radius: 50%;
}
.chat-care .chat-img
{
    display: inline-block;
}
.chat-care .chat-body
{
    display: inline-block;
    max-width: 80%;
    background-color: #FFC195;
    border-radius: 12.5px;
    padding: 15px;
}

.chat-care .chat-body strong
{
  color: #0169DA;
}

.chat-care .admin
{
    text-align: right ;
    float: right;
}
.chat-care .admin p
{
    text-align: left ;
}
.chat-care .agent
{
    text-align: left ;
    float: left;
}
.chat-care .left
{
    float: left;
}
.chat-care .right
{
    float: right;
}

.clearfix {
  clear: both;
}




::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}
.user{
    float: right;
    width: 70%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 8px 0;
    background-color: #FFC195;
}
.replay{
    float: left;
    width: 70%;
    padding: 8px;
    background-color:  #fbedec;
    margin: 10px 0;
    border-radius: 0 8px;
}
.cat-text{
    font-weight: 600;
    font-size: 13px;
    clear: both;
}
.top{
    background:#f3f0f0;
    padding: 5px;
}
.top-title{
    font-weight: bold;
    font-size: 13px;
    padding: 0;
    margin: 0;
}
.top-details{
    font-size: 12px;
    font-weight: bold;
    color: #635c5c;
}
.btn{
    margin-bottom: 2px !important;
}

textarea:focus{
    border: none !important;
}
</style>


