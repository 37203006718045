import { render, staticRenderFns } from "./OrderDetail.vue?vue&type=template&id=6b04df77&scoped=true&"
import script from "./OrderDetail.vue?vue&type=script&lang=js&"
export * from "./OrderDetail.vue?vue&type=script&lang=js&"
import style0 from "./OrderDetail.vue?vue&type=style&index=0&id=6b04df77&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b04df77",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VApp,VBtn,VCard,VDialog,VForm,VSnackbar,VTextField,VTextarea})
