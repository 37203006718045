<template>
    <div>
    <br>
        <!-- <div class="container">
        <div class="row">
                <div class="col-md-6 mx-auto"> -->
                    <div style="width:300px" class="card">
                        <div class="card-header text-center">
                            <span>Your tickets</span>
                            <i style="cursor:pointer" @click="$emit('closeTicketBox')" class="fa fa-minus float-right mt-1"></i>
                        </div>
                        <div class="card-body chat-care" id="messages">
                            <template v-for="item in  tickets">
                                <div :key="item.id" @click="onClickTicket(item)" class="user">
                                    <small class="left text-muted"><span class="glyphicon glyphicon-time"></span>{{moment(item.created_at).fromNow()}}</small>
                                    <p style="margin:0" class="cat-text">{{ item.title }}</p>
                                    <span v-if="item.status == 'open'"> Click to open chat</span>
                                    <span v-else class="p-status-style">{{ item.status }}</span>
                                </div>
                                
                            </template>
                            
                        </div>
                        <div class="card-footer">
                            <div class="input-group">
                                <span class="input-group-btn">
                                    <button style="margin-left: 10px" @click="$emit('onClickAddNewTicket')" class="btn btn-primary" id="btn-chat">
                                        Add new ticket</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div>
        </div>
    </div> -->
</template>

<script>
export default {
    name: 'support-ticket',
    data(){
        return {
            chat: [],
            message: '',
        }
    },
    methods: {
        onClickSend(){
            this.$emit('onClickSend', this.message)
            this.message = ''
        },
        onClickTicket(item){
            this.$emit('onClickTicket', item)
        }
    },//end of methods
    props:{
        tickets: {required: true}
    },
    // mounted(){
    //     console.log(this.chat,'>>>chat>>>>>')
    // }
}
</script>

<style scoped>
    .chat
{
    list-style: none;
    margin: 0;
    padding: 0;
}




.chat-care
{
    overflow-y: scroll;
    height: 250px;
}
.chat-care .chat-img
{
    width: 50px;
    height: 50px;
}
.chat-care .img-circle
{
    border-radius: 50%;
}
.chat-care .chat-img
{
    display: inline-block;
}
.chat-care .chat-body
{
    display: inline-block;
    max-width: 80%;
    background-color: #FFC195;
    border-radius: 12.5px;
    padding: 15px;
}

.chat-care .chat-body strong
{
  color: #0169DA;
}



.clearfix {
  clear: both;
}




::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}
.user{
    padding: 8px;
    margin-top: 5px;
    border-radius: 8px;
    background-color: #FFC195;
}
.replay{
    float: left;
    width: 90%;
    padding: 8px;
    background-color:  #fbedec;
    margin: 10px 0;
    border-radius: 0 8px;
}
.cat-text{
    font-weight: 600;
    font-size: 13px;
    clear: both;
}
.p-status-style{
    color: brown;
    font-size: 12px;
    text-transform: capitalize;
}
.user:hover{
    cursor: pointer;
}
.btn{
    margin-bottom: 2px !important;
}
</style>
